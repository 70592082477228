module.exports = {
    enterNumber: {
        secondTitle: "Welcome",
        tips: "Please input your mobile number to receive a one time verification passcode",
        phonePlaceHolder: "Your phone number",
        phoneRuleMessage: "not correct",
        smallTips: "Your number will remain private",
        getOTP: "Get OTP",
        toastSuccess: "Successful",
        toastFail: "Fail",
    },
    enterOTP: {
        secondTitle: "Enter your OTP",
        tips: "Please enter the five digit one time verification passcode sent via text to your registered mobile",
        resendOTP: "Resend OTP",
        invalidOTP: "Invalid OTP",
        OTPRuleMessage: "The OTP you entered was invalid, please re-enter and try again",
        verify: "Verify",
        ok: "Ok",
    },
    registration: {
        secondTitle: "Multiple Membership Found!",
        noUserSecondTitle: "One More Step",
        tips: "Please complete the form below to confirm your membership details and ensure your loyalty points are awarded for your transactions.",
        formValueTitle: "Title",
        formValueFirstName: "First name",
        formValueLastName: "Last name",
        formValueDateOfBirth: "Date of Birth",
        formValueDay: "Day",
        formValueMonth: "Month",
        formValueYear: "Year",
        formValueMobileNumber: "Mobile Number",
        formValueMobileEmail: "Email",
        formValueMobileGender: "Gender",
        formValueMobileGenderFemale: "Female",
        formValueMobileGenderMale: "Male",
        secondTips1: "I would like receive news, offers and other marketing communications form, and be contacted for surveys by ",
        secondTips2: " and their products as listed in the Pricacy Notice overleaf via:",
        subscribeAll: "All of the following channels",
        marketiong: "Or the marketing channels selected individually",
        marketiongEmail: "Emails",
        marketiongText: "Text Messages (SMS/WhatsApp)",
        marketiongPhone: "Phone Calls",
        confirm1: "By signing below, I confirm I am 18 years old or above, I wish to register my information with ",
        brandName: "Estée Lauder",
        confirm2: ", that i have read , understood and agreed to be bound by the relevant terms and conditions and the Privacy Notice as set out overleaf regarding how my personal data may be used.",
        smallTips1: "I have read and agree to the ",
        smallTips2: "Privacy Policy ",
        smallTips3: " and ",
        termsAndConditions: "Terms and Conditions.",
        smallTips5: "the membership program's ",
        submit: "Submit",
        continue: "Continue with registration",
        oK: "OK",
        cancle: "Cancel",
        statementUnderGender1: "I have read and agree to ",
        possessive: "’s",
        possessive1: "’s",
        privacyPolicy: " Privacy Policy",
        period: ".", 
        statementSubscribe1: "I agree to receive news, offers and other marketing communications from, and be contacted for surveys by, ",
        statementSubscribe2:"Cosmetics Pte. Ltd. (operating entity of ",
        statementSubscribe3: "about any brands owned by its parent company, the Estée Lauder Companies Inc., including any brands acquired or launched in the future, and their products of the same kinds as listed in the ",
        statementSubscribe4:"via:",
        statementButton1:"I am interested in joining ",
        statementButton2:" and by ticking this box I confirm that I am of legal adult age or above and I have read, understood and agree to",
        loyaltyProgram: "The Privilege Program",

    },
    linkConsumer: {
        secondTitle: "Membership added",
        thank: "Thank you, your membership has been successfully added.",
        done: "Done"
    },
    index:{
        copyRight:"Copyright © Estée Lauder 2022"
    }
}
