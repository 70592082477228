import Vue from 'vue';
import App from './App';
import { router } from './router';
import {Toast,Loading} from 'vant'
import VueI18n from 'vue-i18n'

Vue.use(Toast)
Vue.use(Loading)
Vue.use(VueI18n)

//多语言实例
const i18n = new VueI18n({
  locale:(function(){
    return 'en'
  }()),
  messages:{
    'en':require('./assets/language/en'), //英文语言包
    'thai':require('./assets/language/thai'), //泰国语言包
    'en_bobbibrown':require('./assets/language/en_bobbibrown'), // 英文 BB 语言包
    'en_lamer': require('./assets/language/en_lamer'),
    'en_labseries': require('./assets/language/en_labseries'),
    'en_origins': require('./assets/language/en_origins'),
  }
})

new Vue({
  router,
  el: '#app',
  i18n,
  render: h => h(App)
});
