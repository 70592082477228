module.exports = {
    enterNumber: {
        secondTitle: "ยินดีต้อนรับ",
        tips: "กรุณาใส่หมายเลขโทรศัพท์มือถือของท่านเพื่อรับรหัสผ่านยืนยันแบบใช้ครั้งเดียว",
        phonePlaceHolder: "Your phone number",
        phoneRuleMessage: "not correct",
        smallTips: "หมายเลขโทรศัพท์ของท่านจะถูกเก็บเป็นความลับ",
        getOTP: "ขอรหัส OTP",
        toastSuccess: "Successful",
        toastFail: "Fail",
    },
    enterOTP: {
        secondTitle: "ใส่รหัส OTP ของท่าน",
        tips: "กรุณาใส่รหัสผ่านการแบบใช้ครั้งเดียว (ตัวเลขห้าหลัก) จากข้อความ SMS ที่ส่งไปยังโทรศัพท์มือถือที่ท่านลงทะเบียนไว้",
        resendOTP: "ส่ง OTP . อีกครั้ง",
        invalidOTP: "รหัส OTP ไม่ถูกต้อง",
        OTPRuleMessage: "รหัส OTP ที่ท่านใส่ไม่ถูกต้อง กรุณาลองใส่อีกครั้ง",
        verify: "ยืนยัน",
        ok: "ตกลง"
    },
    registration: {
        secondTitle: "พบข้อมูลสมาชิกหลายรายการ",
        noUserSecondTitle: "เหลืออีกหนึ่งขั้นตอน",
        tips: "กรุณากรอกแบบฟอร์มด้านล่างนี้เพื่อยืนยันรายละเอียดการเป็นสมาชิกของท่าน และเพื่อให้ท่านได้รับคะแนนสะสมจากการซื้อสินค้า",
        formValueTitle: "คำนำหน้าชื่อ",
        formValueFirstName: "ชื่อ",
        formValueLastName: "นามสกุล",
        formValueDateOfBirth: "วันเดือนปีเกิด",
        formValueDay: "วันที่",
        formValueMonth: "เดือน",
        formValueYear: "ปีค.ศ.",
        formValueMobileNumber: "Mobile Number",
        formValueMobileEmail: "อีเมล",
        formValueMobileGender: "เพศ",
        formValueMobileGenderFemale: "หญิง",
        formValueMobileGenderMale: "ชาย",
        secondTips: "ฉันต้องการรับข่าวสาร ข้อเสนอ และการสื่อสารการตลาดอื่นๆ รวมถึงการติดต่อเพื่อสำรวจข้อมูลการตลาดจาก Estee Lauder และผลิตภัณฑ์ตามที่ระบุไว้ในประกาศเกี่ยวกับความเป็นส่วนตัวผ่าน:",
        subscribeAll: "ทุกช่องทางต่อไปนี้",
        marketiong: "หรือเลือกช่องทางการตลาด",
        marketiongEmail: "อีเมล",
        marketiongText: "ข้อความ",
        marketiongPhone: "โทรศัพท์",
        confirm: "โดยการลงนามด้านล่าง ฉันยืนยันว่าฉันอายุ 18 ปีขึ้นไป ฉันต้องการลงทะเบียนข้อมูลของฉันกับคลีนิกข์ โดยที่ฉันได้อ่าน เข้าใจ และตกลงที่จะผูกพันตามข้อกำหนดและเงื่อนไขที่เกี่ยวข้องและประกาศเกี่ยวกับความเป็นส่วนตัวตามที่ระบุไว้ข้างต้นเกี่ยวกับวิธีการ ข้อมูลส่วนบุคคลของฉันอาจถูกใช้.",
        smallTips1: "ข้าพเจ้าได้อ่านและยอมรับข้อกำหนดและเงื่อนไข ",
        smallTips2: "นโยบายความเป็นส่วนตัว ",
        smallTips3: " and the membership program's ",
        smallTips4: "Terms and Conditions",
        submit: "ยอมรับ",
        continue: "ลงทะเบียนต่อ",
        brandName: " Estée Lauder",
        oK: "ตกลง",
        cancle: "ยกเลิก",
        statementUnderGender1: "ข้าพเจ้าได้อ่านและยอมรับ",
        privacyPolicy: " นโยบายคุ้มครองความเป็นส่วนตัว",
        possessive: "ของ",
        statementSubscribe1: "ข้าพเจ้าขอให้ความยินยอมและขอรับข่าวสาร ข้อเสนอและการติดต่อสื่อสารอื่น ๆ รวมถึงการติดต่อเพื่อทำแบบสำรวจโดย",
        statementSubscribe2:" บริษัท เอลก้า (ประเทศไทย) จำกัด ",
        statementSubscribe3: " อันเกี่ยวกับแบรนด์ภายใต้บริษัท Estée Lauder Companies Inc. และผลิตภัณฑ์ประเภทเดียวกันกับที่ระบุไว้ใน",
        statementSubscribe4:"ผ่านช่องทางต่าง ๆ เช่น ทางโทรศัพท์ ไปรษณีย์ ข้อความ อีเมล การแจ้งเตือน หรือข้อความอื่น ๆ หรือผ่านโฆษณาที่แสดงให้ข้าพเจ้าทราบถึงผลิตภัณฑ์ และ/หรือ บริการที่ถูกปรับให้เหมาะสมกับความสนใจของข้าพเจ้าบนโซเชียลมีเดียและเว็บไซต์อื่น ๆ ซึ่งรายชื่อแบรนด์เหล่านี้สามารถดูได้ที่:        ",
        statementButton1:"ข้าพเจ้าสนใจเข้าร่วมรายการสะสมรางวัล ",
        statementButton2:"ในการคลิกปุ่มนี้ เป็นการยอมรับว่า ข้าพเจ้าบรรลุนิติภาวะแล้ว และข้าพเจ้าได้อ่านและยอมรับข้อกำหนดและเงื่อนไขของรายการสะสมรางวัล",
        loyaltyProgram: " THE ESTEE LAUDER VIP PROGRAM",
    },
    linkConsumer: {
        secondTitle: "ผูกข้อมูลสมาชิกเรียบร้อยแล้ว",
        thank: "ขอบคุณค่ะ ดำเนินการผูกข้อมูลสมาชิกของท่านเรียบร้อยแล้ว",
        done: "เสร็จสิ้น"
    }
}
