import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    // mode:'history',
    path: '/',
    redirect: '/NotFound'
  },
  {
    name: 'eform',
    component: () => import('./view/eform'),
    meta: {
      title: '主页'
    },
    children: [
      {
        path: '/:countryPath/EnterOpt',
        component: () =>
            import('./view/eform/EnterOpt'),
        name: 'EnterOpt',
        meta: {
          title: 'Enter Otp'
        }
      },
      {
        path: '/:countryPath/EnterNumber/:lang',
        component: () =>
            import('./view/eform/EnterNumber'),
        name: 'EnterNumber',
        meta: {
          title: 'Enter Number'
        }
      },
      {
        path: '/:countryPath/Registration',
        component: () =>
            import('./view/eform/Registration'),
        name: 'Registration',
        meta: {
          title: 'Registration'
        }
      },
      {
        path: '/:countryPath/LinkConsumer',
        component: () =>
            import('./view/eform/LinkConsumer'),
        name: 'LinkConsumer',
        meta: {
          title: 'LinkConsumer'
        }
      },
      {
        path: '/NotFound',
        component: () =>
            import('./view/eform/NotFound'),
        name: 'NotFound',
        meta:{
          title: 'Page not using'
        }
      },
      {
        path: '/Board',
        component: () =>
            import('./view/eform/Board'),
        name: 'Board',
        meta:{
          title: 'Page not using'
        }
      }
    ]
  }
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({mode:'hash',routes:routes});

router.beforeEach((to, from, next) => {
  // if(to.Name!='EnterNumber')

  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  if(to.name=='EnterNumber' || to.name=='NotFound'|| to.name=="Registration" || to.name=='Board'){
    next()
  }else{
    if(checkToken()){
      if((to.name=='EnterNumber')||(to.name=='EnterOpt' && from.name=='EnterNumber')||(to.name=='Registration'&& from.name=='EnterOpt')||(to.name=='LinkConsumer'&& (from.name=='Registration'|| from.name=='EnterOpt'))||to.name=='Registration'){
        if( (from.name=='Registration' && to.name=='LinkConsumer') || (to.name=='Registration'&& from.name=='EnterOpt')){
          localStorage.removeItem("info")
        }
        next()
      }else{
        next({path:'/NotFound'})
      }
      next()
    }else{
      next({path:'/NotFound'})
    }
  }
});


function checkToken(){
  // console(sessionStorage.getItem('link_token') && sessionStorage.getItem('redirect_url'))
  if(sessionStorage.getItem('link_token') && sessionStorage.getItem('redirect_url')){
    return true
  }
  else{
    return false
  }
}


export {
  router
};
